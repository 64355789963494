









































































































import useAttachment from '@/use/attachment'
import useString from '@/use/string'
import { computed, defineComponent, onMounted, onUnmounted, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import { StateChanger } from 'vue-infinite-loading'
import { DataOptions } from 'vuetify'

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = computed(() => root.$store.getters['api/getInstance'] as AxiosInstance)
    const user = computed(() => root.$store.getters['user/getData'])
    const { nl2br, entities } = useString()
    const { truncateFileName, humanReadableFileSize, uploadAttachments, downloadAttachment } = useAttachment({ root })

    const state = reactive({
      data: null as any,
      error: false as boolean | number,
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 20
      },
      total: 0,
      items: [] as any[],
      fetchIdentifier: +new Date(),
      bottomScrollLeft: 0,
      resetInterval: null as any
    })

    const model = reactive({
      message: '',
      attachments: []
    })

    const fetchMessages = ($state: StateChanger) => {
      const { page, itemsPerPage } = state.options as DataOptions
      state.isLoading = true

      axiosInstance.value.get(`chat/${root.$route.params.id}/message`, { params: { page, itemsPerPage } })
        .then(({ data: { total, messages } }) => {
          if (!messages.length) {
            $state.complete()
          } else {
            state.options.page++
            state.total = total
            state.items = [...messages.reverse(), ...state.items]
            $state.loaded()
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            $state.complete()
          } else {
            $state.error()
          }
        })
        .finally(() => state.isLoading = false)
    }

    const reload = () => {
      state.options.page = 1
      const { page, itemsPerPage } = state.options as DataOptions
      state.isLoading = true

      axiosInstance.value.get(`chat/${root.$route.params.id}/message`, { params: { page, itemsPerPage } })
        .then(({ data: { total, messages } }) => {
            state.options.page++
            state.total = total
            state.items = messages.reverse()
            state.fetchIdentifier = +new Date()

            window.scrollTo(0, document.body.clientHeight)
        })
        .finally(() => state.isLoading = false)
    }

    const isCurrentUser = (id: string) => {
      return id == user.value.id
    }

    const onSubmit = async () => {
      const messageData: {
        message: string;
        attachment?: string[];
      } = {
        message: model.message.trim()
      }

      if (messageData.message) {
        if (model.attachments && model.attachments.length) {
          await uploadAttachments(model.attachments)
            .then(({ data }) => {
              messageData.attachment = data.attachments.map((attachment: { id: string }) => attachment.id)
            })
            .catch(error => console.log(error))
        }

        axiosInstance.value.post(`chat/${root.$route.params.id}/message`, messageData)
          .then(() => {
            model.message = ''
            model.attachments = []
            reload()
          })
          .catch(error => console.log(error))
      }
    }

    const newLine = () => {
      model.message = model.message + ''
    }

    onMounted(() => {
      state.isLoading = true
      axiosInstance.value.get(`chat/${root.$route.params.id}`)
        .then(({ data: { chat } }) => {
          state.data = chat
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            state.error = 404
          } else {
            state.error = true
            console.log(error)
          }
        })
        .finally(() => {
          state.isLoading = true
        })
    })

    const onScroll = () => {
      const body = document.body, html = document.documentElement

      const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.offsetHeight)
      const windowHeight = window.innerHeight
      const scrollPos = window.scrollY

      const scrollLeft = documentHeight - (windowHeight + scrollPos)

      state.bottomScrollLeft = scrollLeft
    }

    onMounted(() => {
      window.addEventListener('resize', onScroll)
      window.addEventListener('scroll', onScroll)

      state.resetInterval = setInterval(() => {
        if (state.bottomScrollLeft < 100) {
          reload()
        }
      }, 5000)
    })

    onUnmounted(() => clearInterval(state.resetInterval))

    return { user, nl2br, entities, state, model, isCurrentUser, fetchMessages, onSubmit, newLine, reload, truncateFileName, humanReadableFileSize, downloadAttachment }
  }
})
